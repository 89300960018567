import { useRecoilValue } from "recoil"
import { Question } from "../../questions/QuestionTypes"
import { answerModeData } from "../../state/answerMode"
import { QuestionMedia } from "../questionBasics/QuestionMedia"
import { AnswerReveal } from "./AnswerReveal"
import { AnswerText } from "./AnswerText"

type Props = {
	question: Question
	showAnswer: boolean
	active: boolean
}

export const QuestionBody = ({ question, showAnswer, active }: Props) => {
	const { content, reveal, fullscreen } = question
	const answerMode = useRecoilValue(answerModeData)
	return (
		<>
			{content && (!showAnswer || !reveal) && (
				<section
					style={{
						textAlign: "center",
						position: "relative",
						fontSize: "2em",
						flex: "1 1 auto",
						minWidth: "40vw",
					}}
				>
					<QuestionMedia
						url={`/questions/${content}`}
						alt={question.challenge}
						active={active && !showAnswer}
						fullscreen={fullscreen}
					/>
				</section>
			)}
			{/* <Animated
				animateOnMount={false}
				animationIn="bounceIn"
				animationOut="bounceOut"
				isVisible={answerMode}
			>
				<Answer question={question} />
			</Animated> */}
			{showAnswer && reveal && <AnswerReveal question={question} />}
			{answerMode && <AnswerText question={question} showAnswer={showAnswer} />}
		</>
	)
}
