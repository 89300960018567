import { useMatch, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { questionData } from "../state/questions"

export const useRound = () => {
	const questions = useRecoilValue(questionData)
	const match = useMatch("/:round/*")
	const { round: roundStr } = useParams()
	if (!match) return
	try {
		const round = +roundStr!
		if (round > 0 && round <= questions.length) return round
	} catch {}
}
