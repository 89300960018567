import { QuestionChoice } from "../../questions/QuestionTypes"
import { useColor } from "./ColorContext"
import { GraphLine } from "./GraphLine"

type Props = {
	choice: QuestionChoice
	percentage?: boolean
	showAnswers: boolean
	suffix?: string
	max?: number
}

export const Choice = ({ choice: s, percentage, showAnswers, suffix, max }: Props) => {
	const color = useColor()
	const reveal = showAnswers && s.correct
	return (
		<li
			key={s.option}
			style={{
				position: "relative",
				margin: "0 auto 1em auto",
				opacity: showAnswers && !s.correct ? 0.5 : 1,
				animation: showAnswers && !s.correct ? "fadeout 1s" : "none",
			}}
		>
			<div className={reveal && max === undefined ? "animated heartBeat" : ""} style={{ display: "inline-block" }}>
				<span
					// className={reveal ? "animate__animated animate__heartbeat infinite" : ""}
					style={{
						animation: reveal ? `glowin${color} 1s` : "none",
						textShadow: reveal
							? color === "white"
								? "0 0 0.3em rgba(246,240,255,255), 0 0 0.3em rgba(246,240,255,255)"
								: "0 0 0.3em rgba(0,6,9,255)"
							: "none",
					}}
				>
					{s.option}
					{showAnswers && s.reveal ? ` (${s.reveal})` : null}
				</span>
			</div>
			{showAnswers && max && <GraphLine val={s.data!} max={max} percentage={percentage} suffix={suffix} />}
		</li>
	)
}
