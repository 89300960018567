type Props = {
	src: string
	alt: string
}
export const ForegroundImg = ({ src, alt }: Props) => {
	return (
		<img
			src={src}
			alt={alt}
			style={{ position: "absolute", right: "2em", bottom: "2em", opacity: 0.8, width: "8em" }}
		/>
	)
}
