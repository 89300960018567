import { color, RGBColor } from "d3-color"
import { isMultipleChoiceQuestion, Question, isRangeQuestion, MultipleChoiceQuestion } from "./questions/QuestionTypes"

export const getForegroundColor = (colorStr: string) => {
	const { r, g, b } = color(colorStr) as RGBColor
	return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "black" : "white"
}

export const getMultipleChoiceAnswerString = (question: MultipleChoiceQuestion) => {
	const i = question.choices.findIndex(i => i.correct)
	return `${"ABCD"[i]}: ${question.choices[i].option}`
}
export const getAnswerString = (question: Question) => {
	const answer = isMultipleChoiceQuestion(question) ? getMultipleChoiceAnswerString(question) : question.answer
	if (typeof answer === "object") return `${answer.join(", ")} (${answer.length} points)`
	if (isRangeQuestion(question) && question.tolerance !== undefined) return `${answer} ± ${question.tolerance}`
	return answer.toString()
	// if (typeof answer === "string") return answer
	// return answer.toString()
}

export const getPoints = (question: Question) => {
	if (isMultipleChoiceQuestion(question)) return 1
	if (typeof question.answer !== "object") return 1
	return question.answer.length
}
