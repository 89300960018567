import { useEffect } from "react"
import { useRecoilValue } from "recoil"
import { questionData } from "../state/questions"

/** Preload reveals for given round */
export const useContentCache = (round: number) => {
	const roundQuestions = useRecoilValue(questionData)[round - 1]
	useEffect(() => {
		const media = roundQuestions.filter(i => i.reveal !== undefined).map(i => i.reveal)

		media.forEach(i => {
			const path = `/questions/${i}`
			if (path.endsWith(".mp4")) {
				const vid = document.createElement("video")
				vid.setAttribute("preload", "auto")
				vid.src = path
			} else if (path.endsWith(".m4a")) {
				const aud = document.createElement("audio")
				aud.setAttribute("preload", "auto")
				aud.src = path
			} else {
				document.createElement("img").src = path
			}
		})
	}, [roundQuestions])
}
