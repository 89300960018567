import { useNavigate } from "react-router-dom"
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil"
import { questionData } from "../state/questions"
import { showAnswerData } from "../state/showAnswer"
import { answerModeData } from "../state/answerMode"
import { useRound } from "./useRound"
import { useRoundIndex } from "./useRoundIndex"
import { useEffect } from "react"

export const useNavHandler = () => {
	const navigate = useNavigate()
	const questions = useRecoilValue(questionData)
	const [answerMode, setAnswerMode] = useRecoilState(answerModeData)
	const setShowAnswer = useSetRecoilState(showAnswerData)
	const round = useRound()
	const index = useRoundIndex()

	useEffect(() => {
		if (round === undefined || index === undefined) return

		const handleKeyDown = (e: KeyboardEvent) => {
			const r = questions[round - 1]
			if (e.key === "ArrowRight") {
				if (index === "summary") {
					setAnswerMode(false)
					questions.length > round && navigate(`/${round + 1}/1`)
				} else if (r.length > index) {
					navigate(`/${round}/${index + 1}`)
				} else {
					navigate(`/${round}/summary`)
				}
			} else if (e.key === "ArrowLeft") {
				if (index === "summary") {
					navigate(`/${round}/${r.length}`)
				} else if (index > 1) {
					navigate(`/${round}/${index - 1}`)
				} else if (round > 1) {
					navigate(`/${round - 1}/summary`)
				}
			}
		}
		document.addEventListener("keydown", handleKeyDown)
		return () => document.removeEventListener("keydown", handleKeyDown)
	}, [navigate, round, index, questions, setAnswerMode])

	useEffect(() => {
		setShowAnswer(false)
	}, [round, index, answerMode, setShowAnswer])
}
