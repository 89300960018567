import { useState } from "react"
import { useNavigate } from "react-router-dom"

export const Splash = () => {
	const navigate = useNavigate()
	const [hover, setHover] = useState(false)

	const handleStartClick = () => {
		navigate("/1/1")
	}

	return (
		<section style={{ textAlign: "center", fontSize: "1.75em" }}>
			<h1>
				<span style={{ fontSize: "smaller" }}>Welcome to</span>
				<br />
				THURSDAY NIGHT TRIVIA!
			</h1>
			<p>
				Hosted by the Royal Canadian Legion, Branch 13
				<br />
				and prepared by Ben Davidson
			</p>
			<p>Three rounds, self-score after each.</p>
			<p style={{ fontSize: "smaller" }}>Arrow keys to change slides, space to play content/reveal answer</p>
			<button
				type="button"
				onClick={handleStartClick}
				style={{
					fontSize: "1.5em",
					padding: "0.25em 1em",
					borderRadius: "0.5em",
					backgroundColor: "#dc3545",
					borderColor: "#dc3545",
					color: "white",
					animation: hover ? "glowbutton 0.25s" : "",
					cursor: "pointer",
					WebkitBoxShadow: hover ? "0 0 .4em .25em rgba(220,53,69,0.6)" : "",
				}}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				{/* <FlagIcon /> */}
				Let's Begin!
				{/* <FlagIcon /> */}
			</button>
		</section>
	)
}
