type Props = {
	val: number
	max: number
	percentage?: boolean
	suffix?: string
}
export const GraphLine = ({ val, max, percentage, suffix }: Props) => {
	return (
		<div style={{ animation: "barout 1s", position: "absolute", width: "100%" }}>
			<div
				style={{
					fontSize: "0.5em",
					padding: "0.25em",
					color: "black",
					textDecoration: "none",
					backgroundColor: "white",
					border: "2px solid black",
					// width: `${percentage ? val : (val * 100) / max}%`,
					width: `${(val * 100) / max}%`,
					// WebkitTextStroke: "0",
					height: "2em",
					overflow: "hidden",
					animation: "",
				}}
			>
				{val.toLocaleString()}
				{percentage && "%"}
				{suffix && ` ${suffix}`}
			</div>
		</div>
	)
}
