import { createContext, useContext } from "react"
import { CATEGORIES, CategoryOption } from "../../questions/Categories"
import { getForegroundColor } from "../../utils"

export const ColorContext = createContext("black")
export const useColor = () => useContext(ColorContext)

export const getCategoryForegroundColor = (category: CategoryOption) => {
	const backgroundColor = `#${CATEGORIES[category]}`
	const foregroundColor = getForegroundColor(backgroundColor)
	return foregroundColor
}
