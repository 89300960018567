import { useEffect, useRef, useState } from "react"

type Props = {
	count: number
	play: boolean
}
export const PointCount = ({ count, play }: Props) => {
	const audio = useRef<HTMLAudioElement>(null)
	const [hasPlayed, setHasPlayed] = useState(false)
	useEffect(() => {
		if (!play || hasPlayed || audio.current === null) return
		audio.current.play()
		setHasPlayed(true)
	}, [play, hasPlayed])

	return (
		<div
			style={{
				position: "absolute",
				left: 0,
				bottom: "1em",
				width: "100%",
				color: "black",
				textAlign: "center",
				fontWeight: "bold",
				fontSize: "1.5em",
				zIndex: 2,
			}}
		>
			<img
				src="/star.svg"
				alt="Background"
				style={{
					width: "4em",
					animation: "spin linear 4s infinite",
				}}
			/>
			<div style={{ position: "absolute", textAlign: "center", width: "100%", bottom: "1.7em" }}>{count}</div>
			<audio ref={audio} src="/multipoint.m4a" />
		</div>
	)
}
