import { Animated } from "react-animated-css"
import { useRecoilValue } from "recoil"
import { questionData } from "../state/questions"
import { usePrevious } from "../hooks/usePrevious"
import { RoundIndex } from "./Slider"
import { ColorContext, getCategoryForegroundColor } from "./questions/ColorContext"
import { answerModeData } from "../state/answerMode"
import { showAnswerData } from "../state/showAnswer"
import { QuestionPage } from "./questions/QuestionPage"

type Props = {
	round: number
	activeIndex: RoundIndex
	questionIndex: number
}

const useIsAdvancing = (round: number, index: RoundIndex) => {
	const prevRound = usePrevious(round)
	const prevIndex = usePrevious(index)

	return (
		// if no previous, advancing
		prevRound === undefined ||
		prevIndex === undefined ||
		// if in greater round, advancing
		round > prevRound ||
		// in same round, advancing if now in the summary or ahead of previous
		(round === prevRound && typeof prevIndex === "number" && (index === "summary" || index > prevIndex))
	)
}

export const QuestionSlide = ({ round, activeIndex, questionIndex: i }: Props) => {
	const questions = useRecoilValue(questionData)[round - 1]
	const advancing = useIsAdvancing(round, activeIndex)
	const question = questions[i - 1]

	const answerMode = useRecoilValue(answerModeData)
	const showAnswer = useRecoilValue(showAnswerData)

	// only bother to render nearby slides
	const close = activeIndex === "summary" ? i === questions.length : Math.abs(activeIndex - i) <= 1
	if (!close) return null

	const activeSlide = i === activeIndex
	const color = getCategoryForegroundColor(question.category)

	return (
		<Animated
			isVisible={activeSlide}
			animationIn={advancing ? "slideInRight" : "slideInLeft"}
			animationInDuration={300}
			animationOut="fadeOut"
			animationOutDelay={2000}
			animationOutDuration={0}
			animateOnMount={activeIndex === i}
			style={{
				position: "absolute",
				zIndex: activeSlide ? 2 : 1,
			}}
		>
			<ColorContext.Provider value={color}>
				<QuestionPage {...{ round, index: i, answerMode, question, showAnswer, activeSlide }} />
			</ColorContext.Provider>
		</Animated>
	)
}
