import { Question } from "./QuestionTypes"

export const questions: Question[][] = [
	[
		{
			category: "economics",
			challenge:
				"The government of Canada had total expenses in their fiscal year ending 2020-MAR-31 of $363B.  What were their expenses the following year?",
			citation:
				"https://www.canada.ca/en/department-finance/services/publications/annual-financial-report/2021/report.html",
			choices: [
				{ option: "$411 billion" },
				{ option: "$629 billion", correct: true },
				{ option: "$912 billion" },
				{ option: "$1.37 trillion" },
			],
		},
		{
			id: "cl7pqri797230b6wbj2t3n2p9",
			category: "tech",
			challenge: "Instagram Reels is analogous to YouTube ____",
			reveal: "cl7pqri797230b6wbj2t3n2p9-reveal.jpg",
			citation: "https://blog.youtube/news-and-events/building-youtube-shorts/",
			choices: [{ option: "Clips" }, { option: "Connect" }, { option: "Now" }, { option: "Shorts", correct: true }],
		},
		{
			id: "cl7pr83cw9189b6wbso8qlo7x",
			category: "puzzle",
			challenge: "Which triangle is larger?",
			content: "cl7pr83cw9189b6wbso8qlo7x-content.svg",
			citation: "https://brilliant.org/daily-problems/lattice-area/",
			choices: [
				{ option: "They are the same", correct: true },
				{ option: "Impossible to determine" },
				{ option: "Left" },
				{ option: "Right" },
			],
		},
		// {
		// 	id: "cl7pr833b8848b6wbsi17n9lm",
		// 	category: "music",
		// 	challenge: "Which of these are NOT part of Billie Eilish's full legal name?",
		// 	content: "cl7pr833b8848b6wbsi17n9lm-content.jpg",
		// 	citation: "https://en.wikipedia.org/wiki/Billie_Eilish",
		// 	choices: [
		// 		{ option: "Billie" },
		// 		{ option: "Caterpillar", correct: true },
		// 		{ option: "O'Connell" },
		// 		{ option: "Pirate" },
		// 	],
		// },
		{
			id: "cl7pr83ax9127b6wbqsra45d3",
			category: "tech",
			challenge: "Which of these tech companies has the most employees?",
			choices: [
				// { option: "Alphabet", data: 156_500 },
				{ option: "Amazon", data: 1_608_000, correct: true },
				{ option: "Facebook", data: 58_604 },
				{ option: "Microsoft", data: 221_000 },
				{ option: "Netflix", data: 12_135 },
			],
		},
		{
			id: "cl7pq454n1649b6wb4uetuj6s",
			category: "art",
			challenge: 'How much water/milk would you expect to add to one cup of typical "just-add-water" pancake mix?',
			content: "cl7pq454n1649b6wb4uetuj6s-content.png",
			reveal: "cl7pq454n1649b6wb4uetuj6s-reveal.jpg",
			citation: "https://www.pearlmillingcompany.com/products/pancake_waffle_mixes/original_pancakes",
			choices: [
				{ option: "1/4 cup" },
				{ option: "3/4 cup", correct: true },
				{ option: "2 cups" },
				{ option: "3 cups" },
			],
		},
		{
			category: "economics",
			challenge:
				"According to the World Bank, which country had the highest poverty rate (<$6.85 2017 PPP USD/day) in 2020?",
			citation: "https://data.worldbank.org/indicator/SI.POV.UMIC.GP",
			choices: [
				{ option: "Belarus", data: 0.2 },
				{ option: "Brazil", data: 6.3 },
				{ option: "Ecuador", data: 14.3 },
				{ option: "Indonesia", data: 23.7, correct: true },
			],
		},
		{
			id: "cl7pr834n8879b6wbkyjwoc6h",
			category: "puzzle",
			challenge: "What is the best move? (white's turn)",
			content: "cl7pr834n8879b6wbkyjwoc6h-content.png",
			reveal: "cl7pr834n8879b6wbkyjwoc6h-reveal.png",
			citation: "https://lichess.org/training/109279",
			choices: [{ option: "c5" }, { option: "h4+" }, { option: "Nxe6+" }, { option: "Qg7+", correct: true }],
		},
		{
			id: "cl7pr831t8786b6wb4c2ws1cb",
			category: "games",
			challenge: "What video games was Shigeru Miyamoto (宮本 茂) producer for?",
			content: "cl7pr831t8786b6wb4c2ws1cb-content.jpg",
			citation: "https://en.wikipedia.org/wiki/Shigeru_Miyamoto_gameography",
			choices: [
				{ option: "Super Mario Bros. (NES)" },
				{ option: "The Legend of Zelda: Ocarina of Time (N64)" },
				{ option: "Pokemon Red/Blue (Gameboy)" },
				{ option: "All of the above", correct: true },
			],
		},
		{
			category: "world",
			challenge: "Who was Oliver Cromwell?",
			choices: [
				{ option: "A 16th century Irish writer" },
				{ option: "A 17th century British politician", correct: true },
				{ option: "A 18th century Canadian entrepreneur" },
				{ option: "A 19th century Confederate general" },
			],
			citation: "https://en.wikipedia.org/wiki/Oliver_Cromwell",
			reveal: "oliver-cromwell.jpg",
		},
		{
			id: "cl7pqridf7447b6wbzbrkeupc",
			category: "economics",
			challenge: "Money can be withdrawn tax-free from an RRSP under what two government initiatives?",
			content: "cl7pqridf7447b6wbzbrkeupc-content.png",
			citation:
				"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/rrsps-related-plans/making-withdrawals/home-buyers-plan-lifelong-learning-plan-withdrawals.html",
			choices: [
				{ option: "CCA and HBP" },
				{ option: "CCA and LIRA" },
				{ option: "LIRA and LLP" },
				{ option: "LLP and HBP", correct: true },
			],
		},
		{
			id: "cl7ppq8fw0062b6wb59b3915o",
			category: "film",
			challenge: "When did Sesame Street premiere?",
			content: "cl7ppq8fw0062b6wb59b3915o-content.webp",
			choices: [{ option: "1949" }, { option: "1959" }, { option: "1969", correct: true }, { option: "1979" }],
		},
		{
			id: "cl7pq9hko2665b6wbqtk4eykk",
			category: "life",
			challenge: "What is this?",
			content: "cl7pq9hko2665b6wbqtk4eykk-content.jpg",
			choices: [
				{ option: "Blue grass" },
				{ option: "Bush grass" },
				{ option: "Crab grass", correct: true },
				{ option: "Lemon grass" },
			],
		},
		// {
		// 	id: "cl7pr2xt08680b6wby29zk2z5",
		// 	category: "philosophy",
		// 	challenge: "Order these famous Greek philosophers from oldest to youngest",
		// 	content: "cl7pr2xt08680b6wby29zk2z5-content.png",
		// 	reveal: "cl7pr2xt08680b6wby29zk2z5-reveal.jpg",
		// 	citation:
		// 		"https://www.dummies.com/education/philosophy/socrates-plato-and-aristotle-the-big-three-in-greek-philosophy/",
		// 	choices: [
		// 		{ option: "Aristotle, Plato, Socrates" },
		// 		{ option: "Aristotle, Socrates, Plato" },
		// 		{ option: "Plato, Aristotle, Socrates" },
		// 		{ option: "Socrates, Plato, Aristotle", correct: true },
		// 	],
		// },
		// {
		// 	id: "cl7pr2wsb8177b6wbe8vuiuqn",
		// 	category: "tech",
		// 	challenge: "What does CSV stand for in a typcal .csv file?",
		// 	reveal: "cl7pr2wsb8177b6wbe8vuiuqn-reveal.png",
		// 	choices: [
		// 		{ option: "Comma Separated Values", correct: true },
		// 		{ option: "Command Sequence Values" },
		// 		{ option: "Command Separated Values" },
		// 		{ option: "Computer Sequence Values" },
		// 	],
		// },
		{
			id: "cl7pq9hqq2851b6wb3mdhcawv",
			category: "philosophy",
			challenge: "Which is the foundation of heliocentrism?",
			reveal: "cl7pq9hqq2851b6wb3mdhcawv-reveal.jpg",
			choices: [
				{ option: "Air is the source of life" },
				{ option: "Earth orbits the sun", correct: true },
				{ option: "Greece is at earth's center" },
				{ option: "Light is the source of life" },
			],
		},
		{
			id: "cl7pq9hfz2572b6wbmewt7pd0",
			category: "local",
			challenge: "Which river is NOT a Skeena tributary?",
			choices: [
				{ option: "Bulkley" },
				{ option: "Exchamsiks" },
				{ option: "Kasiks" },
				{ option: "Nass", correct: true },
			],
		},
		// {
		// 	id: "cl7pr83ex9282b6wbvg309n8w",
		// 	category: "economics",
		// 	challenge: "What was the annual TFSA dollar limit for 2021?",
		// 	choices: [
		// 		{ option: "$5,000" },
		// 		{ option: "$6,000", correct: true },
		// 		{ option: "$7,500" },
		// 		{ option: "$10,000" },
		// 	],
		// 	citation:
		// 		"https://www.canada.ca/en/revenue-agency/services/forms-publications/publications/rc4466/tax-free-savings-account-tfsa-guide-individuals.html",
		// },
		{
			id: "cl7pq44x11463b6wbb3wili69",
			category: "world",
			challenge: "Which of these is a non-fictional pirate?",
			content: "cl7pq44x11463b6wbb3wili69-content.png",
			reveal: "cl7pq44x11463b6wbb3wili69-reveal.jpg",
			citation: "https://en.wikipedia.org/wiki/Blackbeard",
			choices: [
				{ option: "Blackbeard", correct: true },
				{ option: "Davy Jones" },
				{ option: "Long John Silver" },
				{ option: "Redbeard" },
			],
		},
		{
			id: "cl7pr2x6i8364b6wbh3xfdpv0",
			category: "industry",
			challenge: "Which clothing company is NOT Canadian?",
			reveal: "cl7pr2x6i8364b6wbh3xfdpv0-reveal.svg",
			choices: [
				{ option: "Arc'teryx" },
				{ option: "Lululemon" },
				{ option: "The North Face", correct: true },
				{ option: "Roots" },
			],
		},
		// {
		// 	id: "cl7pr82zu8724b6wb79u8z84k",
		// 	category: "local",
		// 	challenge: "What is the total annual compensation of the Prime Minister of Canada?",
		// 	content: "cl7pr82zu8724b6wb79u8z84k-content.png",
		// 	choices: [
		// 		{ option: "$188,800" },
		// 		{ option: "$275,000" },
		// 		{ option: "$371,600", correct: true },
		// 		{ option: "$500,000" },
		// 	],
		// },
		// {
		// 	id: "cl7pq9hot2789b6wb9u20oeks",
		// 	category: "literature",
		// 	challenge: 'What is the original answer to "Why did the chicken cross the road?"',
		// 	content: "cl7pq9hot2789b6wb9u20oeks-content.png",
		// 	reveal: "cl7pq9hot2789b6wb9u20oeks-reveal.jpg",
		// 	citation: "https://en.wikipedia.org/wiki/Why_did_the_chicken_cross_the_road%3F",
		// 	choices: [
		// 		{ option: "It was an egg-cellent idea" },
		// 		{ option: "It was free-range" },
		// 		{ option: "Just beak-cause" },
		// 		{ option: "To get to the other side", correct: true },
		// 	],
		// },
		{
			id: "cl7pqri4e7137b6wbffo5lhxr",
			category: "world",
			challenge: "Which region has the most top-10 tallest buildings* in the world?",
			content: "cl7pqri4e7137b6wbffo5lhxr-content.png",
			reveal: "cl7pqri4e7137b6wbffo5lhxr-reveal.png",
			citation: "https://en.wikipedia.org/wiki/List_of_tallest_buildings",
			choices: [
				{ option: "Americas", data: 1 },
				{ option: "Asia", data: 7, correct: true },
				{ option: "Europe", data: 0 },
				{ option: "Middle East", data: 2 },
			],
		},
		{
			id: "cl7pqlbmj5074b6wbr0dth3k6",
			category: "film",
			challenge: "Which is a real storyline on Amazon Prime?",
			content: "cl7pqlbmj5074b6wbr0dth3k6-content.svg",
			reveal: "cl7pqlbmj5074b6wbr0dth3k6-reveal.jpg",
			choices: [
				{ option: "Angelina Jolie babysits underprivileged children" },
				{ option: "Chris Pratt time travels to save the planet from aliens", correct: true },
				{ option: "Dane Cook is a cyber-crime investigator" },
				{ option: "Kanye West is a struggling 1940s fashion designer" },
			],
		},
		{
			id: "cl7pqlbd94733b6wbukrhlb4z",
			category: "national",
			challenge: "What Coat of Arms is this?",
			content: "cl7pqlbd94733b6wbukrhlb4z-content.jpg",
			reveal: "cl7pqlbd94733b6wbukrhlb4z-reveal.jpg",
			choices: [
				{ option: "Hudson Bay Company", correct: true },
				{ option: "Quebec City, QC" },
				{ option: "Simon Fraser University" },
				{ option: "Victoria, BC" },
			],
		},
		{
			id: "cl7pqlb8f4578b6wbqytoa9m7",
			category: "national",
			challenge: "When is Mother's Day in Canada?",
			citation:
				"https://www.cbc.ca/kidscbc2/the-feed/how-do-they-celebrate-mothers-day-in-other-countries#:~:text=Mother%27s%20Day%20takes%20place%20in,should%20have%20a%20special%20day.",
			choices: [
				{ option: "First Saturday of May" },
				{ option: "Second Sunday of May", correct: true },
				{ option: "First Sunday of June" },
				{ option: "Third Sunday of June" },
			],
		},
		// {
		// 	id: "cl7pr839d9065b6wbkcuau3z4",
		// 	category: "science",
		// 	challenge: "How many watts does a typical 100-watt equivalent LED lightbulb consume?",
		// 	content: "cl7pr839d9065b6wbkcuau3z4-content.png",
		// 	choices: [
		// 		{ option: "5 watts" },
		// 		{ option: "15 watts", correct: true },
		// 		{ option: "35 watts" },
		// 		{ option: "60 watts" },
		// 	],
		// },
	],
	[
		{
			id: "cl7pr83pi9461b6wb8qm6al17",
			category: "local",
			challenge: "What is this flag called OR in what year was it replaced as Canada's national flag?",
			content: "cl7pr83pi9461b6wb8qm6al17-content.svg",
			reveal: "cl7pr83pi9461b6wb8qm6al17-reveal.svg",
			citation:
				"https://www.canada.ca/en/canadian-heritage/services/flag-canada-history/timeline-national-flag.html",
			answer: "The Red Ensign, 1965",
		},
		{
			id: "cl7pr83mr9435b6wbb9q3x2w4",
			category: "world",
			challenge:
				"What is the name of the supercontinent believed to have existed until approx. 175 million years ago?",
			reveal: "cl7pr83mr9435b6wbb9q3x2w4-reveal.jpg",
			answer: "Pangaea / Pangea",
		},
		{
			id: "cl7pr83jj9396b6wbqvk44jeo",
			category: "economics",
			challenge: "Who are the two primary credit bureaus in Canada?",
			reveal: "cl7pr83jj9396b6wbqvk44jeo-reveal.svg",
			citation:
				"https://www.canada.ca/en/financial-consumer-agency/services/credit-reports-score/order-credit-report.html",
			answer: "Equifax, TransUnion",
		},
		// {
		// 	id: "cl7pr2we48042b6wba06aetcj",
		// 	category: "world",
		// 	challenge: "What holiday largely overshadows Halloween in Mexico?",
		// 	reveal: "cl7pr2we48042b6wba06aetcj-reveal.jpg",
		// 	citation: "https://en.wikipedia.org/wiki/Day_of_the_Dead",
		// 	answer: "Day of the Dead (Día de Muertos)",
		// },
		{
			id: "cl7pqlc7l5380b6wb3h297eig",
			category: "games",
			challenge: "What is the name of this game?",
			content: "cl7pqlc7l5380b6wb3h297eig-content.jpg",
			reveal: "cl7pqlc7l5380b6wb3h297eig-reveal.jpg",
			answer: "Among Us",
		},
		{
			id: "cl7pr2wr78164b6wb65o8fci0",
			category: "world",
			challenge: "Which Canadian province/territory has the fewest senators per capita?",
			reveal: "cl7pr2wr78164b6wb65o8fci0-reveal.png",
			citation: "https://sencanada.ca/en/senators-list/",
			answer: "British Columbia",
		},
		{
			id: "cl7pr2wq68151b6wbwe0xhde9",
			category: "philosophy",
			challenge: "What is the value of Euler's number (e) to at least 2 decimal places?",
			reveal: "cl7pr2wq68151b6wbwe0xhde9-reveal.png",
			answer: "2.72",
		},
		// {
		// 	id: "cl7pr2xdc8558b6wb9wa47y18",
		// 	category: "tech",
		// 	challenge: "What is this?",
		// 	content: "cl7pr2xdc8558b6wb9wa47y18-content.jpg",
		// 	reveal: "cl7pr2xdc8558b6wb9wa47y18-reveal.jpg",
		// 	answer: "Playstation 5",
		// },
		// {
		// 	id: "cl7pr83hn9357b6wb0n2reckr",
		// 	category: "world",
		// 	challenge: 'What does the term "weeb" mean?',
		// 	reveal: "cl7pr83hn9357b6wb0n2reckr-reveal.jpg",
		// 	citation: "https://www.urbandictionary.com/define.php?term=Weeb",
		// 	answer: "Anime/Japanese culture fanatic",
		// },
		{
			id: "cl7pr2x4j8338b6wb86z2t5s1",
			category: "life",
			challenge: "What kind of tree is this?",
			content: "cl7pr2x4j8338b6wb86z2t5s1-content.jpg",
			answer: "Oak (Angel Oak)",
		},
		{
			id: "cl7pr2xq58654b6wbebwhrwcl",
			category: "health",
			challenge: "What is the medical name of this bone?",
			content: "cl7pr2xq58654b6wbebwhrwcl-content.png",
			citation: "https://en.wikipedia.org/wiki/Coccyx",
			answer: "Coccyx",
		},
		// {
		// 	id: "cl7pq9iyj3412b6wbj6aivn1k",
		// 	category: "film",
		// 	challenge: "Who is this performer?",
		// 	content: "cl7pq9iyj3412b6wbj6aivn1k-content.webp",
		// 	answer: "Robert Pickering Burnham aka Bo Burnham",
		// },
		{
			id: "cl7pqrikl7569b6wbp1v5cxla",
			category: "world",
			challenge: "What river runs through the Grand Canyon?",
			content: "cl7pqrikl7569b6wbp1v5cxla-content.jpg",
			answer: "Colorado River",
		},
		{
			id: "cl7pr2wvz8234b6wb2sznqr7o",
			category: "local",
			challenge: "What entity does this badge represent?",
			content: "cl7pr2wvz8234b6wb2sznqr7o-content.svg",
			citation: "https://forces.ca/en/",
			answer: "Canadian Armed Forces (CAF)",
		},
		{
			id: "cl7pqrieb7478b6wbgr41kyc8",
			category: "film",
			challenge: "Emmy Award trophies feature a woman holding a what?",
			reveal: "cl7pqrieb7478b6wbgr41kyc8-reveal.jpg",
			answer: "Atom",
		},
		{
			id: "cl7pq9hz52921b6wbknqz5ugn",
			category: "tech",
			challenge: "What is Apple's video calling service named?",
			content: "cl7pq9hz52921b6wbknqz5ugn-content.png",
			answer: "FaceTime",
		},
		{
			id: "cl7pr83xt9596b6wbnpr5yb1o",
			category: "film",
			challenge: "Who is this character?",
			content: "cl7pr83xt9596b6wbnpr5yb1o-content.jpg",
			reveal: "cl7pr83xt9596b6wbnpr5yb1o-reveal.mp4",
			answer: "Genie from Aladdin",
		},
		{
			id: "cl7pq9ibf3077b6wbziqxtnoe",
			category: "literature",
			challenge: 'What does the Latin phrase "caveat emptor" mean?',
			answer: "Buyer beware",
			content: "latin.png",
		},
		{
			id: "cl7pr83o39448b6wbmevqjpn8",
			category: "puzzle",
			challenge: "How many circles are needed to balance the bottom equation?",
			content: "cl7pr83o39448b6wbmevqjpn8-content.svg",
			reveal: "cl7pr83o39448b6wbmevqjpn8-reveal.svg",
			citation: "https://brilliant.org/daily-problems/stable-squares/",
			answer: "Two",
			notransition: true,
		},
		// {
		// 	id: "cl7pqo5296507b6wb1ekq5vt1",
		// 	category: "art",
		// 	challenge: "Who is depicted in this painting?",
		// 	content: "cl7pqo5296507b6wb1ekq5vt1-content.jpg",
		// 	answer: "Princess (Queen) Elizabeth",
		// },
		{
			id: "cl7pqi1qc4292b6wb1vrgypxd",
			category: "literature",
			challenge: "What is this meme commonly called?",
			content: "cl7pqi1qc4292b6wb1vrgypxd-content.jpg",
			reveal: "cl7pqi1qc4292b6wb1vrgypxd-reveal.jpg",
			citation: "https://en.wikipedia.org/wiki/Bad_Luck_Brian",
			answer: "Bad Luck Brian",
			notransition: true,
		},
		// {
		// 	id: "cl7pq45ph1911b6wb5u4ptxw0",
		// 	category: "world",
		// 	challenge: "In which state is Burning Man held?",
		// 	content: "cl7pq45ph1911b6wb5u4ptxw0-content.svg",
		// 	reveal: "cl7pq45ph1911b6wb5u4ptxw0-reveal.webp",
		// 	citation: "https://en.wikipedia.org/wiki/Burning_Man",
		// 	answer: "Nevada",
		// },
		{
			id: "cl7pqi1lr4227b6wblkf1se7y",
			category: "local",
			challenge:
				"How many avenues lie between Lakelse Avenue and McConnel Avenue in the 4700 block of Terrace? (exclusive)",
			reveal: "cl7pqi1lr4227b6wblkf1se7y-reveal.png",
			answer: "11: Lazelle, Park, Davis, Walsh, Loen, Olson, Scott, Straume, Hamer, Soucie, Tuck",
		},
		// {
		// 	id: "cl7ppq9es0802b6wbsmbhfa66",
		// 	category: "world",
		// 	challenge: "Name a country on Mexico's southern border",
		// 	reveal: "cl7ppq9es0802b6wbsmbhfa66-reveal.png",
		// 	answer: "Guatemala or Belize",
		// },
		{
			id: "cl7pr83tn9526b6wb2byxfkww",
			category: "science",
			challenge:
				'Fertilizer is often designated with numbers like 24-8-16, referred to as "NPK" respectively.  What do NPK stand for?',
			citation: "https://www.countryliving.com/gardening/a26093411/fertilizer-numbers/",
			content: "cl7pr83tn9526b6wb2byxfkww-content.jpg",
			reveal: "cl7pr83tn9526b6wb2byxfkww-reveal.jpg",
			answer: "Nitrogen, Phosphorus (phosphate), Potassium (potash)",
		},
		{
			id: "cl7pqrih87530b6wbmh56iqhe",
			category: "philosophy",
			challenge: "The odds of rolling the same number on all of three standard dice are 1 in...",
			citation:
				"https://www.vedantu.com/question-answer/in-a-single-throw-of-three-dice-find-the-class-12-maths-cbse-5f71d5eacb93694864d6ef80",
			answer: "36",
		},
		// {
		// 	id: "cl7pq9idp3116b6wbcyksjapa",
		// 	category: "philosophy",
		// 	challenge: "Expand the following: (a - b)(a² + ab + b²)",
		// 	citation: "https://www.mathsisfun.com/algebra/factoring.html",
		// 	answer: "a³ - b³",
		// },
		{
			category: "world",
			challenge: "What is Operation London Bridge?",
			citation: "https://en.wikipedia.org/wiki/Operation_London_Bridge",
			answer: "The transition plan for the death of Queen Elizabeth II",
			content: "london-bridge.jpg",
		},
	],
	[
		{
			id: "cl7pqi42s4539b6wbznhvd0td",
			category: "film",
			challenge: "Name this person",
			content: "cl7pqi42s4539b6wbznhvd0td-content.mp4",
			reveal: "cl7pqi42s4539b6wbznhvd0td-reveal.jpg",
			answer: "Julia Child",
			fullscreen: true,
		},
		{
			id: "cl7pr84cb9731b6wbm79mnm2w",
			category: "music",
			challenge: "Name the song and the original artist",
			content: "cl7pr84cb9731b6wbm79mnm2w-content.mp4",
			reveal: "cl7pr84cb9731b6wbm79mnm2w-reveal.png",
			answer: "Sexy and I Know It, LMFAO",
			fullscreen: true,
		},
		// {
		// 	id: "cl7pr2wmw8112b6wbx8jk0yj8",
		// 	category: "world",
		// 	challenge: "What country is being promoted in this tourism campaign?",
		// 	content: "cl7pr2wmw8112b6wbx8jk0yj8-content.mp4",
		// 	reveal: "cl7pr2wmw8112b6wbx8jk0yj8-reveal.mp4",
		// 	answer: "Kazakhstan (inspired by Borat)",
		// 	fullscreen: true,
		// },
		{
			id: "cl7pqo5kq6598b6wb9b8or2a2",
			category: "film",
			challenge: "Name the actress",
			content: "cl7pqo5kq6598b6wb9b8or2a2-content.mp4",
			reveal: "cl7pqo5kq6598b6wb9b8or2a2-reveal.jpg",
			answer: "Emma Stone (in Cruella)",
			fullscreen: true,
		},
		{
			id: "cl7pr84li9814b6wbts8vlf1q",
			category: "film",
			challenge: "Name the movie and the actor",
			content: "cl7pr84li9814b6wbts8vlf1q-content.mp4",
			reveal: "cl7pr84li9814b6wbts8vlf1q-reveal.jpg",
			answer: "Dead Poets Society (1989), Robert Sean Leonard",
			fullscreen: true,
		},
		// {
		// 	id: "cl7pr84759692b6wbe0cwl3o3",
		// 	category: "music",
		// 	challenge: "Name the original song",
		// 	content: "cl7pr84759692b6wbe0cwl3o3-content.mp4",
		// 	citation: "https://www.youtube.com/watch?v=BtJST96WBm8",
		// 	answer: "Blank Space",
		// 	fullscreen: true,
		// },
		{
			id: "cl7pr84z29923b6wbdmtzyx3u",
			category: "film",
			challenge: "Name the movie",
			content: "cl7pr84z29923b6wbdmtzyx3u-content.mp4",
			reveal: "cl7pr84z29923b6wbdmtzyx3u-reveal.jpg",
			answer: "Office Space (1999)",
			fullscreen: true,
		},
		// {
		// 	id: "cl7pr2xiy8584b6wbk4vda5a7",
		// 	category: "film",
		// 	challenge: "What movie is this?",
		// 	content: "cl7pr2xiy8584b6wbk4vda5a7-content.mp4",
		// 	reveal: "cl7pr2xiy8584b6wbk4vda5a7-reveal.jpg",
		// 	answer: "Starship Tropers",
		// 	fullscreen: true,
		// },
		{
			id: "cl7pq468a2041b6wbymi0jut0",
			category: "music",
			challenge: "What artist is this?",
			content: "cl7pq468a2041b6wbymi0jut0-content.mp4",
			reveal: "cl7pq468a2041b6wbymi0jut0-reveal.webp",
			answer: "The Weeknd (Abel Makkonen Tesfaye)",
			fullscreen: true,
		},
		{
			id: "cl7pr2xer8571b6wbxcctdq3n",
			category: "film",
			challenge: "What TV show is this theme song for?",
			content: "cl7pr2xer8571b6wbxcctdq3n-content.mp4",
			reveal: "cl7pr2xer8571b6wbxcctdq3n-reveal.jpg",
			answer: "The Office",
			fullscreen: true,
		},
		{
			id: "cl7pr84n49827b6wb0nrj2mrr",
			category: "film",
			challenge: "Name the movie",
			content: "cl7pr84n49827b6wb0nrj2mrr-content.mp4",
			reveal: "cl7pr84n49827b6wb0nrj2mrr-reveal.jpg",
			answer: "Blade Runner 2049 (2017)",
			fullscreen: true,
		},
		{
			id: "cl7pr84ap9718b6wbssp865t9",
			category: "music",
			challenge: "Name the artist",
			content: "cl7pqi2mf4383b6wbfeyr2fqe-content.mp4",
			// content: "cl7pr84ap9718b6wbssp865t9-content.mp4",
			reveal: "cl7pqi2mf4383b6wbfeyr2fqe-reveal.mp4",
			// reveal: "cl7pr84ap9718b6wbssp865t9-reveal.jpg",
			answer: "Machine Gun Kelly (Colson Baker)",
			fullscreen: true,
		},
		{
			id: "cl7pr84u99897b6wbeselqe7w",
			category: "film",
			challenge: "Name the movie",
			content: "cl7pr84u99897b6wbeselqe7w-content.mp4",
			reveal: "cl7pr84u99897b6wbeselqe7w-reveal.webp",
			answer: "Meatballs (1979)",
			fullscreen: true,
		},
		// {
		// 	id: "cl7pr840o9622b6wbritiokxp",
		// 	category: "music",
		// 	challenge: "Name the song OR the artist",
		// 	content: "cl7pr840o9622b6wbritiokxp-content.mp4",
		// 	reveal: "cl7pr840o9622b6wbritiokxp-reveal.jpg",
		// 	answer: "Girl Crush by Little Big Town",
		// 	fullscreen: true,
		// },
		{
			id: "cl7pqi2ez4370b6wb1u742ejz",
			category: "music",
			challenge: "Name the song",
			content: "cl7pqi2ez4370b6wb1u742ejz-content.mp4",
			reveal: "cl7pqi2ez4370b6wb1u742ejz-reveal.mp4",
			answer: "Ex's & Oh's (Elle King)",
			fullscreen: true,
		},
		{
			id: "cl7pqrkxa7946b6wb72qfzojv",
			category: "music",
			challenge: "Name the song",
			content: "cl7pqrkxa7946b6wb72qfzojv-content.mp4",
			reveal: "cl7pqrkxa7946b6wb72qfzojv-reveal.mp4",
			answer: "Hit the Road Jack (1962)",
			fullscreen: true,
		},
		// {
		// 	id: "cl7pqrj437738b6wb7rtfyv0b",
		// 	category: "film",
		// 	challenge: "What movie is this?",
		// 	content: "cl7pqrj437738b6wb7rtfyv0b-content.mp4",
		// 	reveal: "cl7pqrj437738b6wb7rtfyv0b-reveal.jpg",
		// 	answer: "Vertigo (1958)",
		// 	fullscreen: true,
		// },
		{
			category: "film",
			challenge: "What show is this?",
			content: "gilligan.mp4",
			reveal: "gilligan.jpg",
			answer: "Gilligan's Island",
			fullscreen: true,
		},
		// {
		// 	id: "cl7pqo7xe6832b6wbtji337xf",
		// 	category: "music",
		// 	challenge: "Name the artist",
		// 	content: "cl7pqo7xe6832b6wbtji337xf-content.mp4",
		// 	answer: "Gwen Stefani",
		// 	reveal: "gwen-stefani.jpg",
		// 	fullscreen: true,
		// },
		{
			category: "music",
			challenge: "Who are these people?",
			content: "coolio.mp4",
			answer: "Coolio (Artis Leon Ivey Jr) and Weird Al Yankovic",
			fullscreen: true,
		},
		{
			id: "cl7pr84jk9801b6wbbdsn35n2",
			category: "film",
			challenge: "Name the movie",
			content: "cl7pr84jk9801b6wbbdsn35n2-content.mp4",
			reveal: "cl7pr84jk9801b6wbbdsn35n2-reveal.jpg",
			answer: "Akira (1988)",
			fullscreen: true,
		},
		{
			id: "cl7pqo76o6767b6wbpqcdewme",
			category: "film",
			challenge: "aWhat movie is this?",
			content: "cl7pqo76o6767b6wbpqcdewme-content.mp4",
			reveal: "cl7pqo76o6767b6wbpqcdewme-reveal.jpg",
			answer: "Galaxy Quest (1999)",
			fullscreen: true,
		},
		// {
		// 	id: "cl7pr2xp48641b6wbbxfyvokt",
		// 	category: "film",
		// 	challenge: "Name the movie",
		// 	content: "cl7pr2xp48641b6wbbxfyvokt-content.mp4",
		// 	reveal: "cl7pr2xp48641b6wbbxfyvokt-reveal.jpg",
		// 	answer: "Oblivion (2013)",
		// 	fullscreen: true,
		// },
		{
			id: "cl7pr84pt9871b6wbp8ir2ml4",
			category: "film",
			challenge: "Name the movie",
			content: "cl7pr84pt9871b6wbp8ir2ml4-content.mp4",
			reveal: "cl7pr84pt9871b6wbp8ir2ml4-reveal.jpg",
			answer: "Hot Fuzz (2007)",
			fullscreen: true,
		},
		{
			id: "cl7pqi1w04318b6wb92czmome",
			category: "music",
			challenge: "Name the song OR the artist",
			content: "cl7pqi1w04318b6wb92czmome-content.mp4",
			reveal: "cl7pqi1w04318b6wb92czmome-reveal.mp4",
			answer: "Born to Die by Lana Del Rey",
			fullscreen: true,
		},
		{
			id: "cl7pqo7u86819b6wbpwtrzhg1",
			category: "film",
			challenge: "What movie is this?",
			content: "cl7pqo7u86819b6wbpwtrzhg1-content.mp4",
			answer: "Chicago (2002)",
			fullscreen: true,
		},
		{
			id: "cl7pqrk9b7842b6wbxlz547nx",
			category: "film",
			challenge: "What movie is this?",
			content: "cl7pqrk9b7842b6wbxlz547nx-content.mp4",
			reveal: "cl7pqrk9b7842b6wbxlz547nx-reveal.jpg",
			answer: "The Naked Gun (1988)",
			fullscreen: true,
		},
		// {
		// 	id: "cl7pq46fs2106b6wbr70quyhq",
		// 	category: "music",
		// 	challenge: "What song is this?",
		// 	content: "cl7pq46fs2106b6wbr70quyhq-content.mp4",
		// 	reveal: "cl7pq46fs2106b6wbr70quyhq-reveal.mp4",
		// 	answer: "I Ain't Worried by OneRepublic",
		// 	fullscreen: true,
		// },
		// {
		// 	id: "cl7pr2xm08597b6wbp11wrqlg",
		// 	category: "film",
		// 	challenge: "What movie is this?",
		// 	content: "cl7pr2xm08597b6wbp11wrqlg-content.mp4",
		// 	reveal: "cl7pr2xm08597b6wbp11wrqlg-reveal.jpg",
		// 	answer: "The Mask of Zorro (1998)",
		// 	fullscreen: true,
		// },
		// {
		// 	id: "cl7pqo7p86806b6wbn0x5swxz",
		// 	category: "film",
		// 	challenge: "What movie is this trailer for?",
		// 	content: "cl7pqo7p86806b6wbn0x5swxz-content.mp4",
		// 	reveal: "cl7pqo7p86806b6wbn0x5swxz-reveal.jpg",
		// 	answer: "Lightyear (2022)",
		// 	fullscreen: true,
		// },
		// {
		// 	id: "cl7pqi3ir4474b6wbez59kgbu",
		// 	category: "music",
		// 	challenge: "Name the band (entire name!)",
		// 	content: "cl7pqi3ir4474b6wbez59kgbu-content.mp4",
		// 	reveal: "cl7pqi3ir4474b6wbez59kgbu-reveal.webp",
		// 	answer: "King Crow and the Ladies from Hell",
		// 	fullscreen: true,
		// },
	],
]
