import { MultipleChoiceQuestion } from "../../questions/QuestionTypes"
import { Choices } from "./Choices"
import { QuestionMedia } from "../questionBasics/QuestionMedia"
import { getAnswerString } from "../../utils"

type Props = {
	question: MultipleChoiceQuestion
	showAnswer: boolean
}

export const MultipleChoiceQuestionBody = ({ question, showAnswer }: Props) => {
	const { content, reveal, notransition, suffix } = question
	return (
		<div style={{ display: "flex", height: "100%", flex: "1 1 1fr" }}>
			<Choices
				choices={question.choices}
				percentage={question.percentage}
				showAnswers={showAnswer}
				suffix={suffix}
			/>
			{(content || reveal) && (
				<section
					style={{
						textAlign: "center",
						fontSize: "2em",
						flex: "1 1 auto",
						minWidth: "40vw",
						position: "relative",
					}}
				>
					{showAnswer && reveal ? (
						<QuestionMedia
							url={`/questions/${reveal}`}
							alt={getAnswerString(question)}
							reveal
							notransition={notransition}
						/>
					) : content ? (
						<QuestionMedia url={`/questions/${content}`} alt={question.challenge} />
					) : null}
				</section>
			)}
		</div>
	)
}
