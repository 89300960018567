import { Question } from "../../questions/QuestionTypes"
import { QuestionMedia } from "../questionBasics/QuestionMedia"
import { getAnswerString } from "../../utils"

type Props = {
	question: Question
}

export const AnswerReveal = ({ question }: Props) => {
	const { reveal, notransition } = question
	const answer = getAnswerString(question)
	if (!reveal) return null
	return (
		<section
			// never animate videos or ones with notransition marked
			className={"answer " + (notransition || reveal.endsWith(".mp4") ? "" : "animated fadeIn fast")}
			style={{
				textAlign: "center",
				fontSize: "2em",
				position: "relative",
				flex: "auto",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<QuestionMedia url={`/questions/${reveal}`} alt={answer} reveal notransition={notransition} active />
		</section>
	)
}
