import { useEffect, useRef } from "react"
import { useColor } from "../questions/ColorContext"
import "./clock.css"

type Props = {
	start?: Date
}
export const Clock = ({ start }: Props) => {
	const secondHand = useRef<SVGLineElement>(null)
	useEffect(() => {
		const { current: sh } = secondHand
		if (!sh) return
		const saveAnimation = sh.style.animation
		sh.style.animation = "none"
		// need to interact with element to ensure animation resets
		const _ = sh.clientHeight // eslint-disable-line
		sh.style.animation = saveAnimation
	}, [start])

	const stroke = useColor()

	return (
		<svg style={{ width: "100%", height: "100%", stroke }} viewBox="0 0 20 20">
			<circle cx={10} cy={10} r={10} fill="transparent" strokeWidth={0.5} />
			{/* <line x1={17} x2={20} y1={10} y2={10} stroke="black" />
			<line x1={0} x2={3} y1={10} y2={10} stroke="black" />
			<line x1={10} x2={10} y1={0} y2={3} stroke="black" />
			<line x1={10} x2={10} y1={17} y2={20} stroke="black" /> */}
			<g transform="translate(10 10)">
				<line
					ref={secondHand}
					x1={0}
					y1={0}
					x2={0}
					y2={-10}
					style={{
						animation: "animate-seconds 60s linear infinite",
					}}
				/>
			</g>
		</svg>
	)
}
