type Props = {
	src: string
	alt: string
}
export const BackgroundImg = ({ src, alt }: Props) => {
	return (
		<div
			style={{
				position: "absolute",
				overflow: "hidden",
				height: "100%",
				width: "100%",
				top: 0,
				left: 0,
				zIndex: 0,
				opacity: 0.1,
			}}
		>
			<img
				src={src}
				alt={alt}
				style={{
					position: "absolute",
					height: "150%",
					top: "-25%",
					left: "2em",
				}}
			/>
		</div>
	)
}
