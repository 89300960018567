import { memo } from "react"
import { CATEGORIES } from "../../questions/Categories"
import { getPoints } from "../../utils"
import { BackgroundImg } from "../questionBasics/BackgroundImg"
import { ForegroundImg } from "../questionBasics/ForegroundImage"
import { PageNumber } from "../questionBasics/PageNumber"
import { QuestionBody } from "./QuestionBody"
import { Citation } from "../questionBasics/Citation"
import { isMultipleChoiceQuestion, Question } from "../../questions/QuestionTypes"
import { MultipleChoiceQuestionBody } from "./MultipleChoiceQuestionBody"
import { PointCount } from "../questionBasics/PointCount"
import { useColor } from "./ColorContext"

type Props = {
	round: number
	index: number
	activeSlide: boolean
	showAnswer: boolean
	question: Question
	answerMode: boolean
}

export const QuestionPage = memo(function QuestionPage({
	round,
	index,
	answerMode,
	question,
	showAnswer,
	activeSlide,
}: Props) {
	const showThisAnswer = showAnswer && activeSlide
	const { category, challenge, citation } = question

	const color = useColor()
	const backgroundColor = `#${CATEGORIES[category]}`
	const icon = `/icons/${category}.svg`

	return (
		<section
			style={{
				backgroundColor,
				margin: 0,
				padding: "3em",
				height: "100vh",
				width: "100vw",
				color,
			}}
		>
			<BackgroundImg src={icon} alt={category} />
			<ForegroundImg src={icon} alt={category} />
			<PageNumber {...{ round, index, answerMode }} />
			<div style={{ zIndex: 1, position: "relative", height: "100%", display: "flex", flexDirection: "column" }}>
				<h1 style={{ fontSize: "2.25em", margin: "0 auto 0.5em auto", textAlign: "center" }}>
					{challenge}
					{citation && showThisAnswer && <Citation url={citation} />}
				</h1>
				{isMultipleChoiceQuestion(question) ? (
					<MultipleChoiceQuestionBody question={question} showAnswer={showThisAnswer} />
				) : (
					<QuestionBody question={question} showAnswer={showThisAnswer} active={activeSlide} />
				)}
			</div>
			{getPoints(question) > 1 && !showAnswer && <PointCount count={getPoints(question)} play={activeSlide} />}
		</section>
	)
})
