import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import { RecoilRoot } from "recoil"
import { BrowserRouter } from "react-router-dom"
// import { Auth } from "./Auth"
import { App } from "./App"

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<RecoilRoot>
				<App />
				{/* <Auth /> */}
			</RecoilRoot>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
