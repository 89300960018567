import { QuestionChoice } from "../../questions/QuestionTypes"
import { Choice } from "./Choice"

type Props = {
	choices: QuestionChoice[]
	percentage?: boolean
	showAnswers: boolean
	suffix?: string
}

const getMax = (vals: number[]) => vals.reduce((p, c) => (c > p ? c : p), Number.MIN_VALUE)

export const Choices = ({ choices, ...props }: Props) => {
	// no data = undefined, percentage = 100, otherwise calculate max
	const max = choices[0].data === undefined ? undefined : getMax(choices.map(c => c.data!))

	return (
		<ol
			style={{
				fontSize: "2em",
				margin: "0 0 0 2em",
				padding: 0,
				listStyleType: "upper-alpha",
				flex: "1 1 auto",
				// width: "100%",
			}}
		>
			{choices.map(i => (
				<Choice key={JSON.stringify(i)} choice={i} {...props} max={max} />
			))}
		</ol>
	)
}
