import { useNavigate } from "react-router-dom"
import { useActiveSlide } from "../../hooks/useActiveSlide"
import { Clock } from "./Clock"

type Props = {
	round: number
	index: number
	answerMode: boolean
}
export const PageNumber = ({ round, index, answerMode }: Props) => {
	const navigate = useNavigate()
	const activeSlide = useActiveSlide(round, index)
	return (
		<div
			style={{
				bottom: "1em",
				left: "1em",
				position: "absolute",
				fontSize: "1.5em",
				cursor: "pointer",
			}}
			onClick={() => navigate(`/${round}/summary`)}
		>
			<div
				style={{
					height: "0.75em",
					width: "0.75em",
					marginRight: "0.3em",
					display: "inline-block",
				}}
			>
				{activeSlide && <Clock />}
			</div>
			{answerMode ? "A" : "Q"}
			{round}.{index}
		</div>
	)
}
