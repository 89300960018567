import { useMatch, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { questionData } from "../state/questions"

export const useRoundIndex = () => {
	const questions = useRecoilValue(questionData)
	const params = useParams()
	const match = useMatch("/:round/:index")
	if (!match) return undefined
	try {
		const round = +params.round!
		if (round < 1 || round > questions.length) throw Error(`round must be between 1 and ${questions.length}`)
		if (params.index === "summary") return "summary"
		const index = +params.index!
		if (index < 1 || index > questions[round - 1].length)
			throw Error(`index must be between 1 and ${questions[round - 1].length}`)
		return index
	} catch {}
}
