export const CATEGORIES = {
	art: "0b1366",
	economics: "0a6660",
	film: "66530b",
	games: "066622",
	health: "0a4166",
	industry: "660a64",
	life: "256605",
	literature: "66400a",
	local: "eeeeee",
	national: "660b0b",
	music: "3b0a66",
	halloween: "ff8c00",
	philosophy: "66290a",
	science: "606605",
	puzzle: "3c3e66",
	tech: "665e3b",
	world: "663c3b",
} as const

export type CategoryOption = keyof typeof CATEGORIES
