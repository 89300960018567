import { useEffect } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { answerModeData } from "../state/answerMode"
import { showAnswerData } from "../state/showAnswer"

export const useAnswerControls = () => {
	const [answerMode, setAnswerMode] = useRecoilState(answerModeData)
	const setShowAnswer = useSetRecoilState(showAnswerData)

	useEffect(() => {
		const handler = (e: KeyboardEvent) => e.key.toUpperCase() === "A" && setAnswerMode(a => !a)
		document.addEventListener("keydown", handler)
		return () => document.removeEventListener("keydown", handler)
	}, [setAnswerMode])

	useEffect(() => {
		const handler = (e: KeyboardEvent) => {
			if (e.key === " " && answerMode) {
				e.preventDefault()
				setShowAnswer(a => !a)
			}
		}
		document.addEventListener("keydown", handler)
		return () => document.removeEventListener("keydown", handler)
	}, [answerMode, setShowAnswer])
}
