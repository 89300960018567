import { useAnswerControls } from "./hooks/useAnswerControls"
import { Route, Routes, useParams } from "react-router-dom"
import { Slider } from "./components/Slider"
import { Splash } from "./components/Splash"

const UrlSlider = () => {
	const { round, index } = useParams()
	return <Slider round={+round!} index={index === "summary" ? "summary" : +index!} />
}

export const App = () => {
	useAnswerControls()
	return (
		<Routes>
			<Route path="*" element={<Splash />} />
			<Route path="/:round/:index" element={<UrlSlider />} />
		</Routes>
	)
}
