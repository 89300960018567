import { CategoryOption } from "./Categories"
export type QuestionChoice = {
	// the displayed data
	option: string
	// numeric values will be graphed automatically in the reveal if given
	// eg. for populations you might graph USA 328_000_000, Canada 35_600_000
	data?: number
	// exactly one answer should be marked correct
	correct?: true
	reveal?: string
}

type BaseQuestion = {
	id?: string
	challenge: string
	category: CategoryOption
	citation?: string

	// an image or video to be provided along with the question
	content?: string
	fullscreen?: boolean
	// after answer, a reveal may be shown, eg. image or video
	reveal?: string
	notransition?: boolean
}
export type MultipleChoiceQuestion = BaseQuestion & {
	// multiple choice questions use this
	choices: QuestionChoice[]
	suffix?: string
	percentage?: true
}
export type AnswerQuestion = BaseQuestion & {
	answer: string | string[]
}
export type RangeQuestion = BaseQuestion & {
	answer: number
	tolerance?: number
}

export function isMultipleChoiceQuestion(q: Question): q is MultipleChoiceQuestion {
	return q.hasOwnProperty("choices")
}

export function isAnswerQuestion(q: Question): q is AnswerQuestion {
	return q.hasOwnProperty("answer") && typeof (q as AnswerQuestion).answer !== "number"
}

export function isRangeQuestion(q: Question): q is RangeQuestion {
	return q.hasOwnProperty("answer") && typeof (q as RangeQuestion).answer === "number"
}

export type Question = MultipleChoiceQuestion | AnswerQuestion | RangeQuestion
