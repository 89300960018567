import { CSSProperties, useEffect, useRef, useState } from "react"

const style: CSSProperties = {
	position: "absolute",
	left: 0,
	right: 0,
	maxHeight: "100%",
	maxWidth: "100%",
	width: "100%",
	objectFit: "contain",
	margin: "0 auto",
}

type Props = {
	url: string
	alt: string
	reveal?: boolean
	notransition?: boolean
	active?: boolean
	fullscreen?: boolean
}
export const QuestionMedia = ({
	url,
	alt,
	reveal,
	active = false,
	notransition = false,
	fullscreen = false,
}: Props) => {
	const videoRef = useRef<HTMLVideoElement>(null)
	const [hasPlayed, setHasPlayed] = useState(false)
	useEffect(() => setHasPlayed(false), [active])
	useEffect(() => {
		const video = videoRef.current
		if (!video) return
		if (active) {
			video.focus()
		} else {
			video.pause()
			video.blur()
		}
	}, [active])

	if (url.endsWith(".mp4"))
		return (
			<video
				ref={videoRef}
				src={url}
				title={alt}
				// autoplay wasn't working?
				// autoPlay={reveal}
				controls
				style={style}
				preload="auto"
				onLoadedData={e => {
					reveal && (e.target as HTMLVideoElement).play()
				}}
				onPlay={e => {
					!hasPlayed && fullscreen && (e.target as any).requestFullscreen()
					setHasPlayed(true)
				}}
				onEnded={async e => {
					try {
						fullscreen && (await document.exitFullscreen())
					} catch {}
				}}
			/>
		)
	return (
		<img
			src={url}
			alt={alt}
			style={{ ...style, height: "100%", animation: reveal && !notransition ? "spinin 2s" : "" }}
			className={notransition || !reveal ? "" : "animated flip"}
		/>
	)
}
