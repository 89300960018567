import { Question, isMultipleChoiceQuestion } from "../../questions/QuestionTypes"
import { getAnswerString } from "../../utils"

type Props = {
	question: Question
	showAnswer: boolean
}

export const AnswerText = ({ question, showAnswer }: Props) => {
	const { reveal, content, notransition } = question
	const answer = getAnswerString(question)
	const vacantSpace = !reveal && !content

	if (isMultipleChoiceQuestion(question)) return null
	return (
		<p
			className={vacantSpace ? "animated zoomIn slow" : notransition ? "animated fadeIn fast" : ""}
			style={{
				marginTop: reveal ? "0" : "1em",
				marginBottom: 0,
				fontWeight: "bold",
				fontSize: vacantSpace ? "4em" : "2em",
				textAlign: "center",
				height: "1em",
			}}
		>
			{showAnswer ? answer : null}
		</p>
	)
}
