import { Animated } from "react-animated-css"
import { useNavHandler } from "../hooks/useNavHandler"
import { Summary } from "./Summary"
import { useRecoilValue } from "recoil"
import { questionData } from "../state/questions"
import { useContentCache } from "../hooks/useContentCache"
import { QuestionSlide } from "./QuestionSlide"

export type RoundIndex = number | "summary"

type Props = {
	round: number
	index: RoundIndex
}

export const Slider = ({ round, index }: Props) => {
	useNavHandler()
	useContentCache(round)
	const questions = useRecoilValue(questionData)[round - 1]

	return (
		<>
			{questions.map((q, i) => (
				<QuestionSlide key={`${round}-${i}`} round={round} activeIndex={index} questionIndex={i + 1} />
			))}
			{index === "summary" && (
				<Animated
					isVisible
					animateOnMount
					animationIn="slideInUp"
					animationOut="slideOutDown"
					style={{ position: "absolute", zIndex: index === "summary" ? 2 : 1 }}
				>
					<Summary round={round} />
				</Animated>
			)}
		</>
	)
}
