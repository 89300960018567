import { useRecoilValue, useRecoilState } from "recoil"
import { Link, useNavigate } from "react-router-dom"
import { getAnswerString, getPoints } from "../utils"
import { answerModeData } from "../state/answerMode"
import { questionData } from "../state/questions"

type SummaryProps = {
	round: number
}
export const Summary = ({ round }: SummaryProps) => {
	const questions = useRecoilValue(questionData)
	const navigate = useNavigate()
	const [answerMode, setAnswerMode] = useRecoilState(answerModeData)
	const roundQuestions = questions[round - 1]
	const roundPoints = roundQuestions.reduce((total, q) => total + getPoints(q), 0)
	return (
		<article style={{ width: "100vw", height: "100vh", background: "white", margin: 0, padding: "1em" }}>
			<h1 style={{ textAlign: "center", margin: "0.5em" }}>
				Round {round} - {roundPoints} points
			</h1>
			<ol style={{ margin: "0.5em 2em" }}>
				{roundQuestions.map((q, i) => (
					<li key={i}>
						<Link to={`/${round}/${i + 1}`} style={{ textDecoration: "none", color: "black" }}>
							<img
								src={`/icons/${q.category}.svg`}
								alt={q.category}
								style={{ width: "1em", maxHeight: "1em", marginRight: "0.25em" }}
							/>
							{q.challenge}
							{getPoints(q) > 1 && <b> ({getPoints(q)} points)</b>}
						</Link>
						{answerMode && <b style={{ marginLeft: "0.5em" }}>{getAnswerString(q)}</b>}
					</li>
				))}
			</ol>
			<div style={{ textAlign: "center" }}>
				<button
					onClick={() => {
						navigate(`/${round}/1`)
						// changing answerMode right away causes reveal before animation is complete
						setTimeout(() => setAnswerMode(true), 200)
					}}
					style={{
						fontSize: "1.5em",
						padding: "0.25em 0.5em",
						marginRight: "1em",
						borderRadius: "0.5em",
						cursor: "pointer",
					}}
				>
					Check Answers
				</button>

				{questions.length > round ? (
					<button
						onClick={() => {
							setAnswerMode(false)
							navigate(`/${round + 1}/1`)
						}}
						style={{
							fontSize: "1.5em",
							padding: "0.25em 0.5em",
							borderRadius: "0.5em",
							cursor: "pointer",
						}}
					>
						Next Round!
					</button>
				) : answerMode ? (
					<p>Thank you, and goodnight! Feedback is appreciated! &mdash; Ben</p>
				) : null}
			</div>
		</article>
	)
}
